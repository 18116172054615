import classnames from "classnames";
import Button, { ButtonType } from "components/Button";
import BackArrow from "components/Icons/Arrowheading";
import CloseIcon from "components/Icons/Close";
import PauseIcon from "components/Icons/Pause";
import PlayIcon from "components/Icons/Play";
import PlayerCloseFullscreen from "components/Icons/PlayerCloseFullscreen";
import PlayerOpenFullscreen from "components/Icons/PlayerOpenFullscreen";
import PlaylistIcon from "components/Icons/Playlist";
import SeekBackwardIcon from "components/Icons/SeekBackward";
import SeekForwardIcon from "components/Icons/SeekForward";
import SkipIntro from "components/Icons/SkipIntro";
import Image, { PlaceholderType } from "components/Image";
import Loader from "components/Loader";
import PlayerAdditionalModal from "components/PlayerAdditionalModal";
import PlayerSubtitleControler from "components/PlayerSubtitleControler";
import PlayerTVShowNavigator from "components/PlayerTVShowNavigator";
import PlayerVolumeControler from "components/PlayerVolumeControler";
import ProgressBar from "components/ProgressBar";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import React, { Component, Fragment } from "react";
import { CSSTransition } from "react-transition-group";

import style from "./style.module.css";

export default class PlayerOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNextProgramInfoVisible: true,
      isNextEpisodeInfoVisible: true,
      showSubtitleController: false,
    };
  }

  handleClickBack(event) {
    event.preventDefault();
    this.props.handleclickonback();
  }

  hideAllController = () => {
    if (this.props.isAdditionalContentVisible) {
      this.props.handleCloseRemote();
    }
    this.setState({
      showSubtitleController: false,
    });
  };

  setShowSubtitleController = (value) => {
    this.props.handleCloseRemote();
    this.setState({ showSubtitleController: value });
  };

  handleClickOverlay(e) {
    e.stopPropagation();
    e.preventDefault();
    if (
      !this.state.showSubtitleController &&
      !this.props.isAdditionalContentVisible
    ) {
      return this.props.setIsOverlayVisibleToFalse();
    } else if (this.state.showSubtitleController) {
      return this.setState({
        showSubtitleController: false,
      });
    } else if (this.props.isAdditionalContentVisible) {
      return this.props.handleCloseRemote();
    }

    if (this.props.config.canPause === false) {
      return;
    }
  }

  handleClickOnPlayPause(e) {
    if (!this.props.config.canPause) {
      return;
    }
    e.stopPropagation();

    if (
      this.state.showSubtitleController ||
      this.props.isAdditionalContentVisible
    ) {
      this.hideAllController();
    }

    this.props.onClickPlayPause();
  }

  handleClickOnSeekBackward(e) {
    e.stopPropagation();
    if (
      this.state.showSubtitleController ||
      this.props.isAdditionalContentVisible
    ) {
      this.hideAllController();
    }
    this.props.onClickSeekBackward();
  }

  handleClickOnSeekForward(e) {
    e.stopPropagation();
    if (
      this.state.showSubtitleController ||
      this.props.isAdditionalContentVisible
    ) {
      this.hideAllController();
    }
    this.props.onClickSeekForward();
  }

  handleClickOnFullscreen(e) {
    e.stopPropagation();
    if (this.props.isAdditionalContentVisible) this.props.onClickRemote();
    if (this.state.showSubtitleController)
      this.setShowSubtitleController(false);
    this.props.onClickFullscreen();
  }

  handleClickOnRemote(e) {
    e.stopPropagation();
    this.setState({
      showSubtitleController: false,
    });
    this.props.onClickRemote();
  }

  handleClickOnCloseNextProgram(e) {
    e.stopPropagation();
    this.setState({ isNextProgramInfoVisible: false });
  }

  formatProgressTime(ms) {
    if (!ms) {
      return "";
    }

    const time = ms / 1000;
    const seconds = Math.floor(time % 60);
    const minutes = Math.floor(time / 60) % 60;
    const hours = Math.floor(time / 60 / 60);

    let result = `${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`;
    if (hours > 0) {
      result = hours + ":" + result;
    }
    return result;
  }

  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  render() {
    const {
      isVisible,
      isPlaying,
      isFullscreen,
      config,
      info,
      onClickProgressBar,
      onChangeVolume,
      isAdditionalContentVisible,
      height,
      width,
      viewport,
      onChangeTrack,
      showLoader,
      isConnected,
      menuOpen,
      t,
      showSkipIntroButton,
      onSkipIntroClick,
    } = this.props;

    const { isNextProgramInfoVisible, showSubtitleController } = this.state;

    return (
      <CSSTransition
        in={isVisible}
        timeout={500}
        classNames={{
          enter: style.overlayEnter,
          enterActive: style.overlayEnterActive,
          enterDone: style.overlayEnterDone,
          exit: style.overlayExit,
          exitActive: style.overlayExitActive,
          exitDone: style.overlayExitDone,
        }}
      >
        <Fragment>
          <div
            className={style.rootContainer}
            onClick={this.handleClickOverlay.bind(this)}
          >
            <div
              className={style.headerContainer}
              onClick={this.handleClickBack.bind(this)}
            >
              <BackArrow
                className={style.headerBack}
                // onClick={this.handleClickBack.bind(this)}
              />
              {info.channel && (
                <div className={style.headerLogo}>
                  <Image
                    src={info.channel.getImage(consts.image_hostname, {
                      width: 171,
                      height: 171,
                      languageId: getFromLocal("languageId"),
                    })}
                    alt="Channel logo"
                    placeholder={PlaceholderType.TRANSPARENT}
                  />
                </div>
              )}
              <div
                className={style.headerTexts}
                // onClick={this.handleClickBack.bind(this)}
              >
                {info.title && (
                  <p className={style.headerTitle}>{info.title}</p>
                )}
                {info.subtitle && (
                  <p className={style.headerSubtitle}>{info.subtitle} </p>
                )}
                {info.rating && (
                  <p className={style.headerRating}> | {info.rating}</p>
                )}
              </div>
            </div>
            <div className={style.centerIconsContainer}>
              {showLoader && <Loader centered={true} />}
              {!showLoader && (
                <>
                  {config.canSeekBackward && (
                    <SeekBackwardIcon
                      className={style.fastSeek}
                      onClick={this.handleClickOnSeekBackward.bind(this)}
                    />
                  )}
                  {config.canPause && (
                    <div
                      className={style.playIconContainer}
                      onClick={this.handleClickOnPlayPause.bind(this)}
                    >
                      {!isPlaying && <PlayIcon className={style.playIcon} />}
                      {isPlaying && <PauseIcon className={style.playIcon} />}
                    </div>
                  )}
                  {config.canSeekForward && (
                    <SeekForwardIcon
                      className={style.fastSeek}
                      onClick={this.handleClickOnSeekForward.bind(this)}
                    />
                  )}
                </>
              )}
            </div>
            {showSkipIntroButton && (
              <Button
                rootClassName={style.skipIntroButton}
                type={ButtonType.NORMAL}
                onClick={onSkipIntroClick}
              >
                <>
                  {t("Skip Intro")} <SkipIntro className={style.skipAdIcon} />
                </>
              </Button>
            )}
            {info && (
              <Fragment>
                {info.isTVShowEpisode && (
                  <PlaylistIcon
                    className={classnames(style.remoteIcon, {
                      [style.remoteIconDisabled]:
                        !info.seasons || info.seasons?.length === 0,
                    })}
                    onClick={
                      info.seasons && info.seasons?.length > 0
                        ? this.handleClickOnRemote.bind(this)
                        : undefined
                    }
                  />
                )}
                <p className={style.progressTime}>
                  {this.formatProgressTime(info.position)} /{" "}
                  {this.formatProgressTime(info.duration)}
                </p>
              </Fragment>
            )}
            <ProgressBar
              rootClassName={style.progressBar}
              position={(info.position / info.duration) * 100}
              draggable={true}
              onClick={(value, submitSeek, callback) => {
                onClickProgressBar(value, submitSeek, callback);
              }}
            />
            <div className={style.bottomContainer}>
              <PlayerVolumeControler
                setVolume={onChangeVolume.bind(this)}
                currentVolume={info.volume}
                setShowSubtitleController={this.setShowSubtitleController.bind(
                  this
                )}
                viewport={viewport}
              />
              {(info?.tracks?.text?.length > 0 ||
                info?.tracks?.audio?.length > 0) && (
                <PlayerSubtitleControler
                  setShowSubtitleController={this.setShowSubtitleController.bind(
                    this
                  )}
                  showSubtitleController={showSubtitleController}
                  hide={isVisible}
                  tracks={info.tracks}
                  handleTrackChange={onChangeTrack.bind(this)}
                  menuOpen={menuOpen}
                />
              )}
              {isFullscreen && (
                <PlayerCloseFullscreen
                  className={style.bottomIcon}
                  onClick={this.handleClickOnFullscreen.bind(this)}
                />
              )}
              {!isFullscreen && (
                <PlayerOpenFullscreen
                  className={style.bottomIcon}
                  onClick={this.handleClickOnFullscreen.bind(this)}
                />
              )}
            </div>
            {isNextProgramInfoVisible && info.nextEPG && info.nextEPG.title && (
              <div className={style.nextEPGContainer}>
                <Image
                  rootClassName={style.nextEPG}
                  width={width}
                  height={height}
                  src={info.nextEPG.imageUrl}
                  placeholder={PlaceholderType.PRIMARY}
                />
                <div className={style.nextEPGInfoContainer}>
                  <div>
                    {info.nextEPG.timeslot && (
                      <p className={style.nextEPGTimeslot}>
                        {info.nextEPG.timeslot}
                      </p>
                    )}
                    <p className={style.nextEPGTitle}>{info.nextEPG.title}</p>
                  </div>
                  <CloseIcon
                    className={style.nextEPGIcon}
                    onClick={this.handleClickOnCloseNextProgram.bind(this)}
                  />
                </div>
              </div>
            )}
          </div>
          {isAdditionalContentVisible && (
            <PlayerAdditionalModal>
              {info.isTVShowEpisode && info.seasons && (
                <PlayerTVShowNavigator
                  assetId={info.assetId}
                  seasons={info.seasons}
                  activeSeason={info.activeSeason}
                  activeEpisode={info.activeEpisode}
                  subscriptionModal={this.subscriptionModal.bind(this)}
                  isFullscreen={isFullscreen}
                  isConnected={isConnected}
                />
              )}
            </PlayerAdditionalModal>
          )}
        </Fragment>
      </CSSTransition>
    );
  }
}
